import { ACTIONS } from '../actions';

const intialState = {
  errorAlert: null,
  successAlert: null,
};

const authReducer = function(state = intialState, action) {
  switch(action.type) {
    case ACTIONS.ERROR_ALERT_SET:
      return Object.assign({}, state, {
        successAlert: null,
        errorAlert: action.msg,
      });
    case ACTIONS.SUCCESS_ALERT_SET:
      return Object.assign({}, state, {
        successAlert: action.msg,
        errorAlert: null,
      });
    default:
      return state;
  }
}

export default authReducer;