export function getCurrentVersionFromBody() {
  if(process.env.NODE_ENV === 'production') {
    for(let script of document.querySelectorAll('body script')) {
      let version = versionFromString(script.src);

      if(version !== null)
        return version;
    }
  }

  return 'development';
}

function versionFromString(str) {
  const regex = /\/js\/main\.(\w+)\.(.+\.)*js/g;
  let match = regex.exec(str);

  if(match !== null && match[1])
    return match[1];

  return null;
}
