import React, { useState } from 'react';
import UploadReceiptDrawer from '../views/upload_receipt_drawer';
import AddIcon from '@material-ui/icons/Add';
import ReceiptsList from '../views/receipts_list';
import { Fab, Zoom, Container } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import store from '../../store';
import { setSuccessAlert } from '../../actions';

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: '#AA0304 !important',
  },
  main: {
    // padding: 0,
  },
}));

export default function({token}) {
  const classes = useStyles(),
    [uploadDrawerOpen, setUploadDrawerOpen] = useState(false),
    [refreshTrigger, setRefreshTrigger] = useState(0),
    theme = useTheme(),
    transitionDuration = {
      enter: theme.transitions.duration.enteringScreen,
      exit: theme.transitions.duration.leavingScreen,
    };

  const onUploadSuccess = msg => {
    store.dispatch(setSuccessAlert(msg));
    setRefreshTrigger(refreshTrigger+1);
  }

  return (
    <Container fixed className={classes.main}>
      <ReceiptsList 
        onReceiptOpen={() => setUploadDrawerOpen(false)} 
        refreshTrigger={refreshTrigger}
      />

      <UploadReceiptDrawer
        open={uploadDrawerOpen}
        onClose={setUploadDrawerOpen.bind(this, !uploadDrawerOpen)}
        onSuccess={onUploadSuccess}
        token={token}
      />
  
      <Zoom
        key={'primary'}
        in
        timeout={transitionDuration}
        style={{
          transitionDelay: `${transitionDuration.exit}ms`,
        }}
        unmountOnExit
      >
        <Fab aria-label={'Add'} className={classes.fab} color={'primary'} onClick={() => setUploadDrawerOpen(!uploadDrawerOpen)}>
          <AddIcon />
        </Fab>
      </Zoom>
    </Container>
  );
}
