import React, { forwardRef, useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  // FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  PhotoCamera,
  Delete,
  Tune,
  RestoreFromTrash,
} from '@material-ui/icons';
import { getDbReceipts, deleteDbReceipts, restoreDbReceipts } from '../../api';
import { getMimeType, DATETIME_FORMAT } from '../../helpers';
import { makeStyles, IconButton, Popover, Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { DateTime } from 'luxon';
import ConfirmDialog from './confirm_dialog';
import _ from 'lodash';

const IMAGE_BASE_URL = process.env.REACT_APP_RECEIPT_IMAGE_BASE_URL;

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  // Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles(theme => ({
  admin: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%', 
    flex: 'auto',
  },
  imgPreviewWrap: {
    textAlign: 'center',
  },
  imgPreview: {
    maxHeight: 530,
  },
  toolbar: {
    display: 'flex',
    padding: 4,
    backgroundColor: 'white',
  },
  optionsPopover: {
    padding: theme.spacing(2),
  }
}));

const columns = [
  { title: "Driver", field: 'username' },
  { title: "Date", field: 'createdAt', type: 'datetime', defaultSort: 'desc', filtering: false, render: rowData => {
    return DateTime.fromISO(rowData.createdAt).toFormat(DATETIME_FORMAT);
  } },
  { title: "Vehicle", field: 'vehicle' },
  { title: "Database", field: 'database' },
  // { title: "Location", render: rowData => {
  //   return rowData.deviceStatus
  //     ? <a href={`https://www.google.com/maps/search/?api=1&query=${rowData.deviceStatus.latitude},${rowData.deviceStatus.longitude}`} target="_blank" rel="noopener noreferrer">{`${rowData.deviceStatus.latitude}, ${rowData.deviceStatus.longitude}`}</a>
  //     : 'Unknown'
  // }},
];

const addVehicleToReceipts = receipts => {
  for (const r of receipts) {
    r.vehicle = r.deviceDetails && r.deviceDetails.length > 0
      ? r.deviceDetails[0].name
      : 'Unknown';
  }

  return receipts;
}

const downloadReceipts = receipts => {
  for (const receipt of receipts) {
    const downloadLink = document.createElement("a"),
      xhr = new XMLHttpRequest();
  
    xhr.open("GET", `${IMAGE_BASE_URL}/${receipt.filename}`, true);
    xhr.responseType = "arraybuffer";
    xhr.onload = function(e) {
      const arrayBufferView = new Uint8Array(this.response);
  
      // get the mime type
      const bytes = [];
      arrayBufferView.slice(0, 4).forEach(byte => bytes.push(byte.toString(16)));
      const hex = bytes.join('').toUpperCase();
      const mimeType = getMimeType(hex);
  
      const blob = new Blob([arrayBufferView], { type: mimeType });
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(blob);
      downloadLink.href = imageUrl;
      downloadLink.download = `${receipt.username.split('@')[0].toLowerCase()}-${receipt.vehicle.toLowerCase()}-${receipt.createdAt}.${receipt.filename.split('.')[1]}`;
      downloadLink.click();
    };
    xhr.send();
  }
}

const deleteReceiptsConfirmed = receipts => {
  return deleteDbReceipts(receipts.map(r => r._id));
}

const restoreReceiptsConfirmed = receipts => {
  return restoreDbReceipts(receipts.map(r => r._id));
}

export default function() {
  const classes = useStyles();
  const [receipts, setReceipts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [range, setRange] = useState([DateTime.local().minus({days: 7}).toJSDate(), DateTime.local().toJSDate()]);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [confirmRestoreOpen, setConfirmRestoreOpen] = useState(false);
  const [selectedReceipts, setSelectedReceipts] = useState([]);
  const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);
  const [showDeletedReceipts, setShowDeletedReceipts] = useState(false);

  useEffect(() => {
    getDbReceipts(range, showDeletedReceipts)
      .then(receipts => {
        setReceipts(receipts);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [range, showDeletedReceipts]);

  let modifiedReceipts = addVehicleToReceipts(receipts);
  const optionsOpen = !!optionsAnchorEl;

  return (
    <div id='admin' className={classes.admin}>
      <div className={classes.toolbar}>
        <div style={{flex: 'auto'}}></div>
        <DateRangePicker 
          value={range}
          onChange={range => [setRange(range), setLoading(true)]}
          clearIcon={null}
        />
      </div>

      <MaterialTable
        icons={tableIcons}
        options={{
          showTitle: false,
          pageSize: 15,
          pageSizeOptions: [5,10,15,20,25,50],
          filtering: true,
          search: false,
          toolbar: true,
          detailPanelColumnAlignment: 'left',
          maxBodyHeight: '80vh',
          selection: true,
          showSelectAllCheckbox: true,
          rowStyle: rowData => ({
            backgroundColor: rowData.ignored ? 'rgb(255, 226, 236)' : '#FFF',
          })
        }}
        isLoading={loading}
        columns={columns}
        data={modifiedReceipts}
        detailPanel={[
          {
            icon: () => <PhotoCamera />,
            tooltip: 'Show receipt',
            render: rowData => (
              <div className={classes.imgPreviewWrap}>
                <img
                  src={`${IMAGE_BASE_URL}/${rowData.filename}`}
                  alt='Receipt preview'
                  className={classes.imgPreview}
                />
              </div>
            )
          }
        ]}
        actions={[
          {
            icon: () => <SaveAlt />,
            tooltip: 'Save receipt(s)',
            onClick: (event, data) => downloadReceipts(data),
          },
          {
            icon: () => <DeleteActionConditionalIcon selectedReceipts={selectedReceipts} />,
            tooltip: _.every(selectedReceipts, ['ignored', true]) ? 'Restore receipt(s)' : 'Delete receipt(s)',
            onClick: (event, data) => {
              if (_.every(selectedReceipts, ['ignored', true])) {
                setConfirmRestoreOpen(true);
              } else {
                setConfirmDeleteOpen(true);
              }
            },
          }
        ]}
        components={{}}
        onSelectionChange={selectedReceipts => setSelectedReceipts(selectedReceipts)}
      />

      {confirmDeleteOpen &&
        <ConfirmDialog
          title="Confirm delete"
          children={
            <span>Are you sure you want to delete {selectedReceipts.length === 1 ? 'this' : 'these'} {selectedReceipts.length} selected {selectedReceipts.length === 1 ? 'receipt' : 'receipts'}?</span>
          }
          open={confirmDeleteOpen}
          setOpen={open => {
            if (!open) {
              setConfirmDeleteOpen(false);
            }
          }}
          onConfirm={async () => {
            setLoading(true);
            await deleteReceiptsConfirmed(selectedReceipts);
            const receipts = await getDbReceipts(range, showDeletedReceipts);
            setReceipts(receipts);
            setLoading(false);
          }}
        />
      }

      {confirmRestoreOpen &&
        <ConfirmDialog
          title="Confirm restore"
          children={
            <span>Are you sure you want to restore {selectedReceipts.length === 1 ? 'this' : 'these'} {selectedReceipts.length} selected {selectedReceipts.length === 1 ? 'receipt' : 'receipts'}?</span>
          }
          open={confirmRestoreOpen}
          setOpen={open => {
            if (!open) {
              setConfirmRestoreOpen(false);
            }
          }}
          onConfirm={async () => {
            setLoading(true);
            await restoreReceiptsConfirmed(selectedReceipts);
            const receipts = await getDbReceipts(range, showDeletedReceipts);
            setReceipts(receipts);
            setLoading(false);
          }}
        />
      }

      <div id='options'>
        <IconButton 
          color="default"
          aria-label="Settings" 
          component="span"
          onClick={e => setOptionsAnchorEl(e.currentTarget)}
        >
          <Tune />
        </IconButton>

        <Popover 
          open={optionsOpen}
          anchorEl={optionsAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={() => setOptionsAnchorEl(null)}
        >
          <Typography className={classes.optionsPopover}>
            <FormControlLabel
              control={
                <Checkbox 
                  checked={showDeletedReceipts} 
                  onChange={e => {
                    setLoading(true);
                    setShowDeletedReceipts(e.target.checked);
                  }} 
                  name="showDeleted" 
                  color="primary"
                />
              }
              label="Show deleted receipts"
            />
          </Typography>
        </Popover>
      </div>
    </div>
  );
}

const DeleteActionConditionalIcon = ({selectedReceipts}) => {
  return _.every(selectedReceipts, ['ignored', true]) ? <RestoreFromTrash /> : <Delete />;
}