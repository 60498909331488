import { ACTIONS } from '../actions';

const intialState = {
  token: null,
};

const authReducer = function(state = intialState, action) {
  switch(action.type) {
    case ACTIONS.TOKEN_SET:
      return Object.assign({}, state, {
        token: action.token,
      });
    default:
      return state;
  }
}

export default authReducer;