import axios from 'axios';
import appContext from '../app_context';
import { Settings } from 'luxon';

export async function getEulaAcceptance() {
  try {
    const response = await axios.get('/api/GetEulaAcceptance?app=fleet_receipts');
    return response.data;
  } catch(ex) {
    console.error(ex);
    throw ex;
  }
}

export async function acceptEula(eulaVersionId, accept) {
  try {
    const response = await axios.post('/api/AcceptEula', {
      eulaVersionId,
      accept,
    });
    return response.data;
  } catch(ex) {
    console.error(ex);
    throw ex;
  }
}

export async function getRecentReceipts() {
  try {
    const response = await axios.get('/api/GetRecentFuelReceipts');
    return response.data;
  } catch(ex) {
    console.error(ex);
    throw ex;
  }
}

export async function getDbReceipts(range, includeDeleted=false) {
  try {
    const response = await axios.get(`/api/GetDbFuelReceipts?startDate=${range[0].toISOString()}&endDate=${range[1].toISOString()}&includeDeleted=${includeDeleted ? 1 : 0}`);
    return response.data;
  } catch(ex) {
    console.error(ex);
    throw ex;
  }
}

export async function deleteDbReceipts(receiptIds) {
  try {
    const response = await axios.post('/api/DeleteDbFuelReceipts', {
      receiptIds,
    });
    return response.data;
  } catch(ex) {
    console.error(ex);
    throw ex;
  }
}

export async function restoreDbReceipts(receiptIds) {
  try {
    const response = await axios.post('/api/RestoreDbFuelReceipts', {
      receiptIds,
    });
    return response.data;
  } catch(ex) {
    console.error(ex);
    throw ex;
  }
}

export function getDbDevices(api=appContext.api, force=false) {
  return new Promise((resolve, reject) => {
    // we may have already run the devices query on this api
    if (!force && api.fleetReceipts && api.fleetReceipts.devices && (api.fleetReceipts.devices instanceof Array)) {
      resolve(api.fleetReceipts.devices);
      return;
    }

    api.call('Get', {
      typeName: 'Device',
      search: {
        fromDate: new Date().toISOString(),
      },
    }, function(err, devices) {
      if (err) {
        reject(err);
        return;
      }

      api.fleetReceipts = Object.assign({}, api.fleetReceipts, {
        devices,
      });
      resolve(devices);
    });
  });
}

export function getUser(api=appContext.api) {
  return new Promise((resolve, reject) => {
    api.call('Get', {
      typeName: 'User',
      search: {
        name: api.credentials.userName,
      },
    }, (err, user) => {
      if (err) {
        reject(err);
        return;
      }

      user = user[0];

      // set default timezone
      appContext.timeZoneId = user.timeZoneId;
      Settings.defaultZoneName = appContext.timeZoneId;

      resolve(user);
    });
  })
}

export async function uploadReceipt(img, deviceId) {
  const formData = new FormData();
  formData.append("image", img);
  if (appContext.state) {
    formData.append("device", appContext.state.device);
  }
  if (deviceId) {
    formData.append("deviceId", deviceId);
  }

  try {
    const response = await axios.post('/api/SubmitFuelReceipt', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return response.data;
  } catch (ex) {
    console.error(ex);
    throw ex;
  }
}