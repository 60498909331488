import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { getEulaAcceptance } from '../../api';
import Admin from '../views/admin';
import Eula from '../views/eula';
import ReceiptsContainer from './receipts_container';

export default function(props) {
  const [eula, setEula] = useState(null);
  
  useEffect(() => {
    if (!eula) {
      getEulaAcceptance()
        .then(eula2 => setEula(eula2));
    }
  }, [eula]);

  if (!eula) return null;

  if (!eula.accepted) {
    return <Eula eula={eula} setEula={setEula} />;
  } else {
    return (
      <Switch>
        <Route path="/admin" component={Admin} />
        <Route path="/" render={() => <ReceiptsContainer {...props} />} />
      </Switch>
    )
  }
}