import appContext from './app_context';
import axios from 'axios';
import localforage from 'localforage';
import store from './store';
import { setToken, setErrorAlert } from './actions';
import { getDbDevices, getUser } from './api';

export function init() {
  return new Promise(async (resolve, reject) => {
    // this will be true if the app is being open from the geotab drive app (contains token param)
    if (hasUrlParts()) {
      return resolve(apiFromUrlParts());
    } else if (window.geotab && process.env.NODE_ENV === 'production') { // production, mygeotab addin
      return resolve(apiFromAddin())
    } else if (process.env.NODE_ENV !== 'production' && !appContext.pwaEnabled) { // development, non-pwa
      return resolve(apiFromLoginCredentials(
        process.env.REACT_APP_GEOTAB_USERNAME,
        process.env.REACT_APP_GEOTAB_PASSWORD,
        process.env.REACT_APP_GEOTAB_DATABASE,
      ));
    } else if (appContext.pwaEnabled) { // production/development, as PWA
      // need to deal with logging in here
      // 
      // lets first check if we have login information in localstorage already
      localforage.getItem('token').then(token => {
        if (token && token !== '') {
          // decode the token
          resolve(apiFromTokenCredentials(token));
        } else {
          // do nothing. will eventually trigger going to login page
          resolve();
        }
      }).catch(err => {
        reject(err);
        return;
      });
    } else {
      resolve();
    }
  }).then(api => {
    // TODO - handle multiple params from apiFromAddin
    return persistApi(api);
  }).catch(ex => {
    console.error(ex);
    store.dispatch(setToken(null));
    return unpersistToken();
  });
}

export async function persistApi(api) {
  // TODO - handle multiple params. add state param, from apiFromAddin. add to appContext
  appContext.api = api;

  try {
    if (appContext.api) {
      let username, database, server, sessionId;
  
      // deals with the slightly different varieties of the geotab api we could have
      if (appContext.api.credentials) {
        const creds = appContext.api.credentials;
        username = creds.userName;
        database = creds.database;
        sessionId = creds.sessionId;
        server = appContext.api.path || creds.serverName || appContext.api.server;
      } else if (appContext.api.getCurrentCredentials) {
        const creds = appContext.api.getCurrentCredentials();
        username = creds.credentials.userName;
        database = creds.credentials.database;
        server = creds.server;
        sessionId = creds.credentials.sessionId;
      } else {
        return;
      }

      // grab the token we've supplemented on the api object (incoming in url)
      // or if it doesnt exist, generate one
      const token = (api.fleetReceipts && api.fleetReceipts.token) ? api.fleetReceipts.token : generateToken({
        username,
        database,
        server,
        sessionId,
      });
      
      if (process.env.NODE_ENV !== 'production') {
        console.log('Development token:', token);
      }
      
      // set axios defaults
      axios.defaults.headers.authorization = token;
  
      store.dispatch(setToken(token));
      return await persistToken(token);
    } else {
      store.dispatch(setToken(null));
      return await unpersistToken();
    }
  } catch(ex) {
    store.dispatch(setToken(null));
    return await unpersistToken();
  }
}

async function persistToken(token) {
  if (!appContext.adminEnabled)
    return await localforage.setItem('token', token);
}

async function unpersistToken() {
  return await localforage.setItem('token', null);
}

export async function logout() {
  await unpersistToken();
  store.dispatch(setToken(null));
}

function apiFromAddin() {
  return new Promise((resolve, reject) => {
    window.geotab.addin.coheseFnsFleetReceipts = (api, state) => {
      return {
        initialize: (api, state, callback) => {
          console.log('initializing...');
          callback();
          resolve([api, state]);
        },
        focus: (api, state) => {
          // User interface is available
          console.log('focus...');
        },
        blur: (api, state) => {
          // Save any Add-In state
          console.log('blur...');
        }
      };
    };
  });
}

async function apiFromUrlParts() {
  const urlParts = getUrlParts();
  // const token = generateToken(urlParts);
  // remove token from url
  document.location.hash = document.location.hash.split('?')[0] || "";
  const token = urlParts.token;
  return await apiFromTokenCredentials(token);
}

export async function apiFromLoginCredentials(username, password, database) {
  const GeotabApi = require('./vendor/mg-api-node');

  if (process.env.NODE_ENV !== 'production') {
    if (username === '' && password === '' && database === '') {
      username = process.env.REACT_APP_GEOTAB_USERNAME;
      password = process.env.REACT_APP_GEOTAB_PASSWORD;
      database = process.env.REACT_APP_GEOTAB_DATABASE;
    }
  }

  let api = new GeotabApi(username, password, database);

  api = await new Promise((resolve, reject) => {
    api.authenticate((err, result) => {
      if (err) {
        reject(err);
        return;
      }

      const path = result.path === 'ThisServer' ? 'my.geotab.com' : result.path;

      api.credentials = result.credentials;
      api.path = path;
      api.server = path;

      resolve(api);
    });
  });

  return api;
}

async function apiFromTokenCredentials(token) {
  const GeotabApi = require('./vendor/mg-api-node');
  const creds = generateCredentials(token);

  let api = new GeotabApi(
    creds.username,
    '',
    creds.database,
    creds.server,
    {},
    creds.sessionId,
  );

  // we want to persist this incoming token, so need to pass it around somehow. attach to api object
  api.fleetReceipts = {token};

  // we need to check if these credentials are still valid
  try {
    // meanwhile we'll save the devices to the api object
    await Promise.all([
      getDbDevices(api),
      getUser(api),
    ]);
  } catch(ex) {
    store.dispatch(setErrorAlert("Login has expired"));
    throw ex;
  }

  return api;
}

function getUrlParts(url=document.location.href) {
  const searchParams = new URLSearchParams(`?${new URL(url).hash.split('?')[1]}`);
  return {
    token: searchParams.get('token'),
  };
}

function hasUrlParts() {
  const actualParts = getUrlParts();
  const requiredParts = [
    "token",
  ];

  for (const part of requiredParts) {
    if (!actualParts[part] || actualParts[part] === "") {
      return false;
    }
  }

  return true;
}

function generateToken({username, sessionId, database, server}) {
  return btoa(JSON.stringify({
    username,
    database,
    server,
    sessionId,
    svcAcct: 'fleet-receipts',
  }));
}

export function generateCredentials(token) {
  if (token) {
    const authHex = token,
          authStr = atob(authHex),
          creds = JSON.parse(authStr);

    if (!creds.username || !creds.database || !creds.server || !creds.sessionId)
      throw new Error('Invalid credentials');

    return creds;
  } else {
    throw new Error('Invalid credentials');
  }
}