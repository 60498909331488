import { Settings } from 'luxon';

export default {
  api: null,
  state: null,
  version: 'development',
  pwaEnabled: false,
  adminEnabled: false,
  timeZoneId: Settings.defaultZoneName,
}
