import React, { useEffect, useState } from 'react';
import { getRecentReceipts } from '../../api';
import { Avatar, List, ListItem, ListItemText, ListItemAvatar, SwipeableDrawer, Paper, makeStyles, CircularProgress, Fab } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { DateTime } from 'luxon';
import { 
  // FormatListBulleted, 
  // LocalGasStation,
  Receipt,
} from '@material-ui/icons';
import { DATETIME_FORMAT } from '../../helpers';

const useStyles = makeStyles(() => ({
  receiptPreview: {
    width: '80%',
    margin: '5% 0',
    boxShadow: '0 0 15px #666',
    // maxHeight: '60%',
  },
  drawerClose: {
    position: 'absolute',
    top: 36,
    right: '13%',
  }
}));

export default function({onReceiptOpen, refreshTrigger}) {
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent),
    classes = useStyles(),
    [receipts, setReceipts] = useState([]),
    [selectedReceipt, setSelectedReceipt] = useState(null),
    [loading, setLoading] = useState(false);

  useEffect(() => {
    refreshReceipts();
  }, [refreshTrigger]);
  
  const refreshReceipts = (doSetLoading=true) => {
    setLoading(doSetLoading);
    getRecentReceipts()
      .then(receipts => {
        setReceipts(receipts);
      })
      .finally(() => {
        if (doSetLoading)
          setLoading(false);
      });
  }
  
  return (
    <React.Fragment>
      {loading &&
        <div className='loading-spinner'>
          <CircularProgress />
        </div>
      }

      {!loading &&
        <List>
          {receipts.map(rcpt => (
            <ListItem 
              key={rcpt._id}
              selected={!!selectedReceipt && rcpt._id === selectedReceipt._id}
              onClick={() => setSelectedReceipt(rcpt)}
            >
              <ListItemAvatar>
                <Avatar>
                  {/* <FormatListBulleted /> */}
                  {/* <LocalGasStation /> */}
                  <Receipt />
                </Avatar>
              </ListItemAvatar>

              <ListItemText 
                primary={DateTime.fromISO(rcpt.createdAt).toFormat(DATETIME_FORMAT)}
                secondary={`${rcpt.deviceDetails && rcpt.deviceDetails.length > 0 ? `${rcpt.deviceDetails[0].name}` : ''}`}
              />
            </ListItem>
          ))}
        </List>
      }

      <SwipeableDrawer
        open={!!selectedReceipt}
        onClose={() => setSelectedReceipt(null)}
        onOpen={() => { onReceiptOpen() }}
        disableBackdropTransition={!iOS} 
        disableDiscovery={iOS}
        anchor='bottom'
        id='receipt-drawer'
      >
        {!!selectedReceipt &&
          <>
            <div className={classes.drawerClose}>
              <Fab size='medium' onClick={() => setSelectedReceipt(null)} style={{cursor: 'pointer'}}>
                <Close />
              </Fab>
            </div>
            
            <Paper square style={{textAlign: 'center'}}>
              <img
                src={`${process.env.REACT_APP_RECEIPT_IMAGE_BASE_URL}/${selectedReceipt.filename}`}
                alt="Receipt preview"
                id='receipt-preview'
                className={classes.receiptPreview}
              />
            </Paper>
          </>
        }
      </SwipeableDrawer>
    </React.Fragment>
  )
}
