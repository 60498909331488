import React, { useEffect, useState, createRef } from 'react';
import { 
  SwipeableDrawer, 
  Typography, 
  Fab,
  Grid,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import { PhotoCamera, RadioButtonUnchecked, CheckCircle, Error, LocalShipping, Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
// import ImageTools from '../../vendor/ImageTools';
import { uploadReceipt, getDbDevices } from '../../api';
import { green } from '@material-ui/core/colors';
import 'image-capture';
import { generateCredentials } from '../../geotab';
import _ from 'lodash';
import loadImage from 'blueimp-load-image';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
// const imageTools = new ImageTools();

const dims = {
  width: 1350,
  height: 1800,
  // width: 1800,
  // height: 1350,
};

const useCustomVideo = true;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
  },
  header: {
    display: 'flex',
    backgroundColor: '#AA0304',
    // height: 15,
    // marginLeft: -theme.spacing(1),
    // marginRight: -theme.spacing(1),
    // marginTop: -theme.spacing(1),
    padding: '3px 5px',
    color: 'white',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
  grid: {
    display: 'flex',
    flex: 1,
    margin: theme.spacing(1),
    width: 'auto !important',
    height: '100%',
  },
  uploadPreview: {
    width: '100%',
    // filter: 'grayscale()',
  },
  receiptMask: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    right: 0,
    top: 'auto',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
  },
  // maskBg: {
  //   position: 'absolute',
  //   top: 0,
  //   left: 0,
  //   right: 0,
  //   bottom: 0,
  //   filter: 'blur(5px)',
  // },
  receiptStream: {
    width: '100% !important',
    height: 'auto !important',
    maxHeight: '100%',
    // height: '75% !important',
    // filter: 'grayscale()',
  },
  input: {
    display: 'none',
  },
  fab: {

  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  wrapperDiv: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
  gridItemCenter: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    height: '100%',
  },
  submitWrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -8,
    left: -8,
    zIndex: 1,
  },
  submitFabSuccess: {
    backgroundColor: 'rgba(76, 175, 80, 0.5) !important',
  },
  submitFabError: {
    backgroundColor: 'rgba(220, 96, 99, 0.5) !important',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  vehicleSelect: {
    margin: theme.spacing(1),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  drawerClose: {
    position: 'absolute', 
    top: 24, 
    right: 24,
  },
}));

function stopStream() {
  if (window.stream) {
    window.stream.getTracks().forEach(track => {
      track.stop();
    });
  }
}

function stopStreamDelayed(ms=1000) {
  setTimeout(stopStream, ms); // hack which seems to fix a chromium issue where the ui would freeze right after a picture was taken from the stream
}

async function getStream() {
  stopStream();

  try {
    const portrait = window.screen.width < window.screen.height;

    const vidEl = document.getElementById('receipt-stream');
    const elSize = {
      width: vidEl.clientWidth,
      height: vidEl.clientHeight,
    };
    
    const constraints = {
      audio: false,
      video: {
        // width: { min: 600, max: 1200 },
        // height: { min: 800, max: 1600 },
        // width: { min: 600 },
        // height: { min: 800 },
        // width: { min: 600, ideal: 1350 },
        // height: { min: 800, ideal: 1800 },
        height: { 
          // min: portrait ? 480 : 640, 
          ideal: portrait ? 1350 : 1800, 
          max: portrait ? elSize.width : elSize.height 
        },
        width: { 
          // min: portrait ? 640 : 480, 
          ideal: portrait ? 1800 : 1350, 
          max: portrait ? elSize.height : elSize.width 
        },
        aspectRatio: portrait ? 4/3 : 3/4,
        // aspectRatio: 3/4,
        // aspectRatio: 16/9,
        facingMode: 'environment',
      },
      // video: true,
    };

    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    return gotStream(stream);
  }
  catch (error) {
    console.error('Error obtaining stream:', error);
    throw error;
  }
}

function gotStream(stream) {
  window.stream = stream; // make stream available to console
  var video = document.getElementById('receipt-stream')
  video.srcObject = stream;
}

async function processImage(img) {
  const data = await loadImage(img, { canvas: true, maxWidth: dims.width, maxHeight: dims.height });
  return new Promise((resolve, reject) => {
    data.image.toBlob(blob => resolve(blob), img.type);
  });

  // const data = await loadImage(img, { canvas: true, maxWidth: dims.width, maxHeight: dims.height });
  // return data.image.toDataURL();
}

async function takePhoto() {
  if (window.stream) {
    const mediaStreamTrack = window.stream.getVideoTracks()[0];
    const imageCapture = new ImageCapture(mediaStreamTrack);
    const pc = await imageCapture.getPhotoCapabilities();
    const photoSettings = {
      imageHeight: pc.imageHeight ? pc.imageHeight.max || dims.height : dims.height,
      imageWidth: pc.imageWidth ? pc.imageWidth.max || dims.width : dims.width,
    }
    return await imageCapture.takePhoto(photoSettings);
  }
}

function UploadReceiptDrawer({onSuccess, onClose, open, token}) {
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent),
    classes = useStyles(),
    uploadInput = createRef(),
    creds = generateCredentials(token),
    [streamSupported, setStreamSupported] = useState(true),
    [receiptImage, setReceiptImage] = useState(null),
    [receiptImageSrc, setReceiptImageSrc] = useState(null),
    [submitLoading, setSubmitLoading] = useState(false),
    [submitSuccess, setSubmitSuccess] = useState(false),
    [submitError, setSubmitError] = useState(false),
    [availableDevices, setAvailableDevices] = useState([]),
    [selectedDeviceId, setSelectedDeviceId] = useState(creds.deviceId || ''),
    [actualDeviceId, setActualDeviceId] = useState(null);

  const onImageChange = async e => {
    try {
      const file = e.target.files[0];
  
      if (file) {
        const img = await processImage(file);
        setReceiptImage(img);
        setReceiptImageSrc(URL.createObjectURL(img));
      } else {
        console.error('no file');
      }
    } catch(ex) {
      console.error(ex);
    }
  }

  const onUploadClick = () => {
    try {
      uploadInput.current.click();
    } catch(ex) {
      console.log(ex);
    }
  }

  const onCaptureStreamClick = async () => {
    const photo = await takePhoto();
    const img = await processImage(photo);
    setReceiptImage(img);
    setReceiptImageSrc(URL.createObjectURL(img));
    stopStreamDelayed();
  }

  const onTakePhoto = async dataUri => {
    const img = await processImage(dataUri);
    setReceiptImage(img);
    setReceiptImageSrc(URL.createObjectURL(img));
  }

  const onSubmitUploadClick = async () => {
    setSubmitLoading(true);
    try {
      await uploadReceipt(receiptImage, actualDeviceId);
      setSubmitSuccess(true);
      onSuccess('Receipt uploaded');
      setTimeout(onClose, 1500);
    } catch(ex) {
      setSubmitLoading(false);
      setSubmitError(true);
      setTimeout(() => {
        setSubmitError(false);
      }, 2500);
    }
  }

  const tryGetStream = async () => {
    try {
      // apparently safari doesnt support ImageCapture
      if (ImageCapture) {
        await getStream();
      } else {
        setStreamSupported(false);
      }
    } catch(ex) {
      setStreamSupported(false);
    }
  }

  useEffect(() => {
    if (open) {
      (async () => {
        await getDbDevices()
          .then(devices => {
            setAvailableDevices(devices);
          });
        if (creds.deviceId) {
          setActualDeviceId(creds.deviceId);
        }
      })();
    }
  }, [open, creds]);

  useEffect(() => {
    if (useCustomVideo && actualDeviceId) {
      tryGetStream();
    }
  }, [actualDeviceId]);

  useEffect(() => {
    if (!open) {
      stopStreamDelayed();

      return () => {
        setReceiptImage(null);
        setReceiptImageSrc(null);
        setSubmitLoading(false);
        setSubmitSuccess(false);
        setActualDeviceId(null);
        // setSelectedDeviceId('');
        setAvailableDevices([]);
      }
    }
  }, [open]);

  return (
    <SwipeableDrawer
      open={open}
      onClose={() => [onClose(), stopStreamDelayed()]}
      onOpen={() => {}}
      disableBackdropTransition={!iOS} 
      disableDiscovery={iOS}
      anchor='bottom'
      id='upload-drawer'
      className={[
        actualDeviceId && streamSupported && !receiptImage ? 'stretched' : '',
      ].join(' ')}
    >
      <div id='upload-drawer-inner' className={classes.root}>
        <header className={classes.header}>
          <LocalShipping fontSize='small' style={{marginRight: 4}} />
          {actualDeviceId
            ? _.find(availableDevices, d => d.id === actualDeviceId).name
            : 'No vehicle'}
        </header>

        <Grid className={classes.grid} container spacing={3}>
          <Grid item xs={12} className={classes.gridItemCenter}>
          {/* <div className={classes.gridItemCenter}> */}
            {!actualDeviceId && availableDevices.length === 0 &&
              <span>No vehicles available</span>
            }
            
            {!actualDeviceId && availableDevices.length > 0 &&
              <div className={classes.vehicleSelect}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="deviceId">Vehicle</InputLabel>
                  <Select
                    native
                    // defaultValue={selectedDeviceId}
                    value={selectedDeviceId}
                    onChange={e => setSelectedDeviceId(e.target.value)}
                    label="Vehicle"
                    inputProps={{
                      name: 'deviceId',
                      id: 'deviceId',
                    }}
                  >
                    <option value=''></option>
                    {_.sortBy(availableDevices, d => d.name).map(d => <option key={d.id} value={d.id}>{d.name}</option>)}
                  </Select>
                </FormControl>

                {selectedDeviceId &&
                  <Fab
                    aria-label='Set vehicle'
                    color='primary'
                    onClick={() => setActualDeviceId(selectedDeviceId)}
                    className={classes.fab}
                    variant='extended' 
                    role='button'
                    style={{cursor: 'pointer'}}
                  >
                    <LocalShipping className={classes.extendedIcon} />
                    Select vehicle
                  </Fab>
                }
              </div>
            }

            {actualDeviceId &&
              <React.Fragment>
                {!streamSupported && !receiptImage &&
                  <Typography component="div" className={classes.wrapperDiv}>
                    <input 
                      accept="image/*" 
                      className={classes.input} 
                      id="icon-button-file" 
                      type="file" 
                      ref={uploadInput} 
                      capture="camera"
                      // capture="environment"
                      onChange={onImageChange}
                    />
                    <label htmlFor="icon-button-file">
                      <Fab aria-label={'Upload'} className={classes.fab} color={'primary'} variant='extended' role='button' onClick={onUploadClick} style={{cursor: 'pointer'}}>
                        <PhotoCamera className={classes.extendedIcon} />
                        Upload Photo
                      </Fab>
                      {/* <IconButton color="primary" aria-label="upload picture" component="span">
                        <PhotoCamera />
                      </IconButton> */}
                    </label>
                  </Typography>
                }
    
                {streamSupported && !receiptImage && useCustomVideo &&
                  <video 
                    autoPlay 
                    muted 
                    playsInline 
                    id='receipt-stream'
                    className={classes.receiptStream}
                  ></video>
                } 

                {streamSupported && !receiptImage && !useCustomVideo &&
                  <Camera
                    idealFacingMode={FACING_MODES.ENVIRONMENT}
                    onTakePhoto={onTakePhoto}
                    isImageMirror={false}
                    idealResolution={{
                      width: 1800,
                      height: 1350,
                    }}
                    // isMaxResolution
                    // isFullscreen
                  />
                }

                {receiptImage && receiptImageSrc &&
                  <div id='upload-preview-container'>
                    <img 
                      alt="Receipt preview" 
                      id="upload-preview" 
                      // src={URL.createObjectURL(receiptImage)}
                      src={receiptImageSrc}
                      className={classes.uploadPreview}
                    />
                  </div>
                }
    
                {(streamSupported || (!streamSupported && !!receiptImage)) &&
                  <>
                    <div className={classes.drawerClose} style={receiptImage ? { right: '17%' } : {}}>
                      <Fab size='medium' onClick={onClose} style={{cursor: 'pointer'}}>
                        <Close />
                      </Fab>
                    </div>

                    <div id='receipt-mask' className={classes.receiptMask}>
                      {!receiptImage && useCustomVideo &&
                        <Fab size='medium' onClick={onCaptureStreamClick} style={{cursor: 'pointer'}}>
                          <RadioButtonUnchecked />
                        </Fab>
                      }

                      {/* {!receiptImage && useCustomVideo &&
                        <Fab size='medium' onClick={onCaptureStreamClick} style={{cursor: 'pointer', position: 'absolute', top: 0, right: 0}}>
                          <Cancel />
                        </Fab>
                      } */}
      
                      {receiptImage &&
                        <div className={classes.submitWrapper}>
                          <Fab 
                            size='medium' 
                            onClick={onSubmitUploadClick} 
                            disabled={submitLoading || submitSuccess || submitError}
                            className={submitSuccess ? classes.submitFabSuccess : submitError ? classes.submitFabError : ''}
                          >
                            {/* <CheckCircle style={{ color: submitSuccess ? green[500] : 'rgba(0, 0, 0, 0.87)' }} /> */}
                            {submitError &&
                              <Error />
                            }
                            {!submitError &&
                              <CheckCircle  />
                            }
                          </Fab>
                          {submitLoading && !submitSuccess && <CircularProgress size={64} className={classes.fabProgress} />}
                        </div>
                      }
                    </div>
                  </>
                }
              </React.Fragment>
            }
          </Grid>
        </Grid>
      </div>
    </SwipeableDrawer>
  );
}

export default UploadReceiptDrawer;
