import React, { useState, useEffect } from 'react';
import { TextField, Container, Typography, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import geotabLogo from '../../images/mygeotab-logo.svg';
import * as geotab from '../../geotab';
import { Redirect } from 'react-router-dom';
import store from '../../store';
import { setErrorAlert } from '../../actions';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function() {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [database, setDatabase] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading]   = useState(false);

  useEffect(() => {
    geotab.logout();
  }, []);

  const onFormSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const api = await geotab.apiFromLoginCredentials(username, password, database);
      await geotab.persistApi(api);
      setRedirect(true);
    } catch(ex) {
      setLoading(false);
      store.dispatch(setErrorAlert(ex.message));
      console.error(ex);
    }
  }

  if (redirect) {
    return (
      <Redirect to={{
        pathname: '/',
      }} />
    );
  }
  
  return (
    <Container id='login' component='main' maxWidth='xs'>
      <div className={classes.paper}>
        <img src={geotabLogo} alt="Geotab logo" />

        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
      
        <form className={`${classes.form} ${loading ? 'loading' : ''}`} noValidate onSubmit={onFormSubmit}>
          <TextField 
            id='username' 
            label='Username' 
            variant='outlined' 
            margin="normal"
            required
            fullWidth
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={e => setUsername(e.target.value)}
            disabled={loading}
          />
          <TextField 
            required 
            id='password' 
            label='Password' 
            variant='outlined' 
            margin="normal"
            fullWidth
            name="password"
            type="password"
            autoComplete="current-password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            disabled={loading}
          />
          <TextField 
            required 
            id='database' 
            label='Database' 
            variant='outlined' 
            margin="normal"
            fullWidth
            name="database"
            autoComplete="database"
            value={database}
            onChange={e => setDatabase(e.target.value)}
            disabled={loading}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
          >
            Sign In
          </Button>
        </form>

        {loading &&
          <div className='loading-spinner'>
            <CircularProgress />
          </div>
        }
      </div>
    </Container>
  );
}
