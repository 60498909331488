import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, AppBar, Toolbar, Snackbar, CssBaseline } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';
import { Switch, Route, Redirect } from 'react-router-dom';
import logoFull from '../../images/logofull.png';
import Login from '../views/login';
import MainContainer from './main_container';
import { connect } from 'react-redux';
import { generateCredentials } from '../../geotab';
import appContext from '../../app_context';
import store from '../../store';
import { setErrorAlert, setSuccessAlert } from '../../actions';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
  logo: {
    height: 44,
  },
  grow: {
    flexGrow: 1,
  },
  currentUser: {
    textAlign: 'right',
    lineHeight: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingLeft: 5,
  },
  toolbar: {
    // paddingRight: 0,
  },
}));

axios.interceptors.response.use(response => {
  // do something with the response data
  return response;
}, error => {
  if (error instanceof axios.Cancel) {
    // do nothing
  } else {
    store.dispatch(setErrorAlert(error.response ? error.response.data : error.message));
    throw error;
  }
});

const PrivateRoute = ({component: Component, ...rest }) => (
  <Route {...rest} render={(props) => {
    return rest.token
      ? <Component {...props} token={rest.token} />
      : <Redirect to={{
          pathname: '/login',
        }} />
  }} />
)

function RootContainer({token, errorAlert, successAlert}) {
  const classes = useStyles();
  const [creds, setCreds] = useState(null);

  useEffect(() => {
    try {
      setCreds(generateCredentials(token));
    } catch(ex) {
      setCreds(null);
    }
  }, [token]);
  
  return (
    <React.Fragment>
      <CssBaseline />

      <Snackbar 
        open={!!errorAlert} 
        autoHideDuration={5000} 
        onClose={() => store.dispatch(setErrorAlert(null))}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      >
        <Alert onClose={() => store.dispatch(setErrorAlert(null))} severity="error">
          {errorAlert}
        </Alert>
      </Snackbar>

      <Snackbar 
        open={!!successAlert} 
        autoHideDuration={5000} 
        onClose={() => store.dispatch(setSuccessAlert(null))}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      >
        <Alert onClose={() => store.dispatch(setSuccessAlert(null))} severity="success">
          {successAlert}
        </Alert>
      </Snackbar>

      <Typography component="div" style={{ height: '100vh', position: 'relative', display: 'flex', flexDirection: 'column' }}>
        <AppBar position="sticky" color='default'>
          <Toolbar className={classes.toolbar}>
            <img src={logoFull} className={classes.logo} alt='Fleet Receipts' />

            <div className={classes.grow} />

            {!!token && !!creds && !appContext.adminEnabled &&
              <React.Fragment>
                <Typography variant="subtitle2" className={classes.currentUser}>
                  {creds.username}
                  <Typography variant="caption" component="div" className={classes.currentUser}>
                    {creds.database}
                  </Typography>
                </Typography>
                {/* <IconButton onClick={logout}>
                  <ExitToApp />
                </IconButton> */}
              </React.Fragment>
            }
          </Toolbar>
        </AppBar>

        <Switch>
          <Route path="/login" component={Login} />

          <PrivateRoute 
            path="/"
            token={token}
            component={MainContainer}
          />
        </Switch>
      </Typography>
    </React.Fragment>
  )
}

const mapStateToProps = function(store) {
  return {
    token: store.authState.token,
    errorAlert: store.alertState.errorAlert,
    successAlert: store.alertState.successAlert,
  };
}

export default connect(mapStateToProps)(RootContainer);