export const ACTIONS = {
  TOKEN_SET: 'TOKEN_SET',
  ERROR_ALERT_SET: 'ERROR_ALERT_SET',
  SUCCESS_ALERT_SET: 'SUCCESS_ALERT_SET',
}

export function setToken(token) {
  return {
    type: ACTIONS.TOKEN_SET,
    token,
  };
}

export function setErrorAlert(msg) {
  return {
    type: ACTIONS.ERROR_ALERT_SET,
    msg,
  };
}

export function setSuccessAlert(msg) {
  return {
    type: ACTIONS.SUCCESS_ALERT_SET,
    msg,
  };
}