import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as geotab from './geotab';
import './axios_config';
import { getCurrentVersionFromBody } from './version';
import appContext from './app_context';
import localforage from 'localforage';
import memoryStorageDriver from 'localforage-memoryStorageDriver';
import { Provider } from 'react-redux';
import store from './store';
import Visibility from 'visibilityjs';

appContext.pwaEnabled = process.env.REACT_APP_PWA_ENABLED === 'true';
appContext.version = getCurrentVersionFromBody();
appContext.adminEnabled = !!~document.location.hash.split('?')[0].indexOf('admin');
console.log(appContext.version);

let onVisibleListenerId;
if (!appContext.adminEnabled) {
  // only want to do this forced refresh thing for the !admin page
  // this deals with some weirdness with how iOS and the drive app
  // will refresh or not refresh the page, resulting in vehicle
  // assignments being lost sometimes
  Visibility.change((e, state) => {
    if (Visibility.hidden()) {
      onVisibleListenerId = Visibility.onVisible(() => {
        Visibility.unbind(onVisibleListenerId);
        window.location.reload();
      });
    }
  });
}

// this protects from browsers that have security settings cranked up
localforage.defineDriver(memoryStorageDriver);
localforage.config({
  driver: [localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE, memoryStorageDriver._driver],
  name: appContext.adminEnabled ? 'fnsFleetReceiptsAdmin' : 'fnsFleetReceipts',
});

(async function() {
  await geotab.init();
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('fnsFleetReceiptsRoot')
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (appContext.adminEnabled) {
  serviceWorker.unregister();
} else {
  serviceWorker.register();
}
