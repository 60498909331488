import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'html-react-parser';
import { acceptEula } from '../../api';
import store from '../../store';
import { setErrorAlert } from '../../actions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  actions: {
    '& > button': {
      margin: theme.spacing(1),
    }
  },
  eula: {
    padding: theme.spacing(1),
    border: '1px solid black',
    height: 500,
    overflowY: 'scroll',
    '& > h1:first-child': {
      marginTop: 0,
    },
  },
}));


function Eula({eula, setEula}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  
  const acceptEulaClick = async (accept, eulaVersionId) => {
    setLoading(true);
    const acceptance = await acceptEula(eulaVersionId, accept);
    const newEula = {...eula};
    newEula.accepted = acceptance.accepted;
    setEula(newEula);
    setLoading(false);
    if (!newEula.accepted) {
      store.dispatch(setErrorAlert('EULA must be accepted in order to use the app'));
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.actions}>
        <Button 
          variant='contained' 
          color='primary'
          onClick={acceptEulaClick.bind(null, true, eula.eula._id)}
          disabled={loading}
        >Accept</Button>
        <Button 
          variant='contained' 
          color='secondary'
          onClick={acceptEulaClick.bind(null, false, eula.eula._id)}
          disabled={loading}
        >Decline</Button>
      </div>

      <div className={classes.eula}>
        {parse(eula.eula.html)}
      </div>
    </div>
  )
}

export default Eula;